// https://www.youtube.com/watch?v=7rE_ew4GXLE

import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import TwinTowers from "../images/twin-towers.jpg"
import { useImpression } from "../analytics/utils"

const September11Attacks = ({ location }) => {
  useImpression("September11Attacks")
  return (
    <Layout location={location}>
      <Seo
        title="September 11 Attacks"
        description="The worst day in the history of the United States, 9/11. Two planes take down the World Trade Center buildings in Manhattan, New York."
      />
      <img
        src={TwinTowers}
        alt="Lower Manhattan from a distance with twin towers in sight."
        width="500"
        style={{ float: "right", padding: 20 }}
      />
      <h1>9/11 &mdash; September 11th, 2001</h1>
      <p>
        Nobody can forget the what happened on 9/11, 2001. No matter what age
        you were, you knew something was wrong, really wrong.
      </p>
      <h2>What happened</h2>
      <p>
        19 terrorists hijacked four passenger planes and flew two of them into
        the World Trade Center buildings in Lower Manhattan. The two towers
        would last no more than an hour and 42 minutes before collapsing and
        releasing immense clouds of dust and debris for miles in every
        direction.
      </p>
      <p>World Trade Center 7 also collapsed due to significant fire damage.</p>
      <h2>Who was responsible</h2>
      <p>
        The Islamic terrorist group Al-Qaeda coordinated the terrorist attacks,
        which left 2,996 people dead. Thousands more were injured.
      </p>
      <p>
        In 1988, Osama bin Laden founded Al-Qaeda. It began as a militant
        Islamist organization, and grew to become a full fledged network of
        terrorists. Besides the 9/11 terrorist attacks, they've even claimed
        responsibility for the 1998 U.S. embassy bombings, and the Bali bombings
        of 2002.
      </p>
      <h2>Response by the United States</h2>
      <h5>The War on Terror</h5>
      <p>
        The United States swiftly launched a War on Terror. An invasion of
        Afghanistan followed, in an effort to wipe out the Taliban. Although the
        Taliban ignored the demands to extradite Osama bin Laden, the U.S.
        Navy's SEAL Team Six successfully killed him in May 2011.
      </p>
    </Layout>
  )
}
export default September11Attacks
